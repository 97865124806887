<!-- <template>
    <div>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Nombre de Archivo</th>
              <th>Descargar</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(file, index) in files" :key="index">
                <td class="file-name">Archivo {{ index + 1 }}</td>
                <td class="file-name">{{ file.name }}</td>
                <td>
                    <v-btn depressed fab x-small color="primary" @click="download(file.file[0].url)">
                    <v-icon>mdi-download-outline</v-icon>
                    </v-btn>
                </td>
                <td>
                    
                </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
  
      <v-row class="mt-4">
        <v-col cols="12" sm="6">
          <v-file-input
            accept=".pdf"
            v-model="fileToUpload"
            truncate-length="150"
            show-size
            label="Seleccionar archivo"
            class="full-file-name"
          ></v-file-input>
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn class="primary" @click="uploadFile">
            Cargar
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </template> -->
<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Nombre de Archivo</th>
            <th>Descargar</th> <!-- Añadir cabecera para la columna de descarga -->
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(file) in file" :key="file.id">
            <td>{{ file.name }}</td>
            <td>
              <v-btn depressed fab x-small color="primary" @click="download(file.file[0].url)">
                <v-icon>mdi-download-outline</v-icon>
              </v-btn>
            </td>
            <td><v-btn depressed fab x-small color="red" @click="deleteFile(file.id)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row>
      <v-col class="text-center">
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-file-input accept=".pdf, .xls, .xlsx, .doc, .docx, .txt" v-model="fileToUpload" truncate-length="15"></v-file-input>
        <v-btn class="primary" @click="uploadFile">
          Cargar
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { mapActions } from 'vuex';

export default {
  name: 'file-legal-list',

  data() {
    return {
      file: [], // Usado para almacenar los archivos cargados
      fileToUpload: null,
    };
  },
  mounted() {
    this.fetchFilesLegal()
      .then(file => {
        this.file = file; // Asegúrate de que la asignación se corresponda con la estructura de datos retornada
      })
      .catch(error => {
        console.error('Error al cargar archivos:', error);
      });
  },
  methods: {
    ...mapActions('filesControl', ['uploadFileLegal', 'fetchFilesLegal']),

    handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) return; // Si no hay archivo, no hacer nada

      this.fileToUpload = file; // Guardar el archivo para subirlo

      this.uploadFile(); // Llamar a uploadFile directamente
    },

    uploadFile() {
      if (this.fileToUpload) {
        // Construir el objeto con el archivo, nombre, y código
        const fileInfo = {
          file: this.fileToUpload,
          name: this.fileToUpload.name, // Extraer el nombre del archivo seleccionado
        };
        this.uploadFileLegal(fileInfo)
          .then(() => {
            // Una vez que el archivo se haya cargado, actualiza la lista de archivos
            this.fetchFilesLegal()
              .then(files => {
                this.files = files;
              });
          })
          .finally(() => {
            this.fileToUpload = null;
          });
      }
    },

    fetchFilesLegal() {
      this.$store.dispatch('filesControl/fetchFilesLegal')
        .then(response => {
          // Asegúrate de manejar y asignar la respuesta adecuadamente aquí
          this.file = response; // Asegúrate de que esto corresponde a la estructura de tu respuesta
        })
        .catch(error => {
          console.error('Error al cargar archivos:', error);
        });
    },

    metadata() {
      // Asegurarse de que file está definido antes de acceder a sus propiedades.
      if (!this.file) {
        return {}; // Retorna un objeto vacío si file aún no está definido.
      }

      let data = { ...this.file.file[0] }; // Asume que file es un objeto que contiene un objeto file interno.
      data.size = this.$utils.formatBytes(this.data.size * 1000);
      data.url = process.env.VUE_APP_API + this.data.url;
      return data;
    },

    download(url) {
      url = process.env.VUE_APP_API + url;
      console.log(url);
      window.open(url, '_blank');
    },
  }
};
</script>

<style>
.file-box {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
}

.file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
  /* Ajusta esto según sea necesario */
}
</style>