<template>
	<v-row justify="center">
		<v-col cols="12"
			><h2 class="text-h4">
				Ebook legal rol ACM
			</h2></v-col
		>
		<v-col cols="12">
			<FileLegalList />
		</v-col>
	</v-row>
</template>

<script>
	import FileLegalList from '@/components/FileLegalList';

	export default {
		name: 'file-legal',

		components: {
			FileLegalList,
		},
	};
</script>
